import { useEffect, useState } from "react";
import { useProspectedCampaignLeadProfiles } from "../../../../shared/domains/campaigns/graphql/hooks/useProspectedCampaignLeadProfiles";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { PAGE_SIZE } from "../data";
import { ColumnSort } from "@tanstack/react-table";
import { translateSortingQuery } from "../utils/index";
import { useCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { useTagCampaignLeadProfileAPI } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSendMessageAPI } from "../../../../shared/domains/conversations/rest/sendMessageAPI";
import {
  useToggleReadStatusOnConversationThread,
  ToggleReadActionType,
  ToggleReadType,
} from "../../../../shared/domains/conversations/rest/toggleReadStatusAPI";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { useDebounce } from "../../../../shared/hooks/UseDebounce";
import dayjs from "dayjs";
import {
  ACTION_DUE_DATE,
  ACTION_DUE_DATE_MAP,
  conversationValueMap,
  ConversationValueOption,
  INFINITY_IN_DAYS_FOR_MEETMORE,
  INIFITY_FOR_MAX_CONVERSATION_VALUE,
} from "../data/data";
import { useCurrentUserContext } from "../../../../shared/domains/users/graphql/hooks/useCurrentUserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../shared/infrastructure/firebase";
import { SALE_ACTION_STATUS } from "../../../../shared/constants/models";

export const QUERY_PARAM_LEAD_PROFILE_ID = "lead-profile-id";

export default function Conversations() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [lastMessageFilterDays, setLastMessageFilterDays] = useState<number>(0);
  const [actionDueDate, setActionDueDate] = useState<number>(ACTION_DUE_DATE.ALL);
  const [conversationValueOption, setConversationValueOption] = useState<number>(
    ConversationValueOption.DEFAULT
  );
  const [outreachStatusActiveOption, setOutreachStatusActiveOption] = useState<number>(0);

  const [saleActionStatusIds, setSaleActionStatusIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [user] = useAuthState(auth);
  const { currentWorkspaceId } = useCurrentUserContext({
    externalId: user?.uid || "",
  });

  const { saleActionStatuses } = useSaleActionStatuses();

  const DEFAULT_SALE_ACTION_STATUS_IDS = saleActionStatuses
    .filter(status => {
      const isNotDealLost = status.id !== SALE_ACTION_STATUS.DEAL_LOST;
      const isNotUnknown = status.id !== SALE_ACTION_STATUS.UNKNOWN;
      const isNotQualified = status.id === SALE_ACTION_STATUS.NOT_QUALIFIED;

      return isNotUnknown && isNotDealLost && !isNotQualified;
    })
    .map(status => status.id);

  useEffect(() => {
    if (saleActionStatuses.length > 0) {
      setSaleActionStatusIds(DEFAULT_SALE_ACTION_STATUS_IDS);
    }
  }, [saleActionStatuses]);

  const handleSearchNameChange = (needle: string) => {
    setSearchTerm(needle);
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const onSaleActionStatusChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setSaleActionStatusIds(DEFAULT_SALE_ACTION_STATUS_IDS);
      setOffset(0);
      setSearchTerm("");
    } else {
      setSaleActionStatusIds(selectedIds);
      setOffset(0);
      setSearchTerm("");
    }
    setTriggerFilter(false);
  };

  const handleLastReplyFilterChange = (value: number) => {
    setLastMessageFilterDays(value);
    setOffset(0);
  };

  const handleConversationValueFilterChange = (value: number) => {
    setConversationValueOption(value);
    setOffset(0);
    setTriggerFilter(false);
  };

  const handleOutreachStatusChange = (value: number) => {
    setOutreachStatusActiveOption(value);
    setOffset(0);
  };

  const handleActionDueDateChange = (value: number) => {
    setActionDueDate(value);
    setOffset(0);
  };

  const columns = getColumns({
    onEditClick: async row => {
      searchParams.set(QUERY_PARAM_LEAD_PROFILE_ID, String(row.original.id));
      setSearchParams(searchParams);
    },
  });

  const currentCampaignLeadProfileId = searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID)
    ? Number(searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID))
    : null;

  useEffect(() => {
    const load = async () => {
      await fetchCampaignLeadProfileById({ variables: { id: currentCampaignLeadProfileId } });
    };

    if (currentCampaignLeadProfileId) {
      load();
    }
  }, [searchParams]);

  useEffect(() => {
    const load = async () => {
      await fetchCampaignLeadProfileById({ variables: { id: currentCampaignLeadProfileId } });
    };

    if (currentCampaignLeadProfileId) {
      load();
    }
  }, [searchParams]);

  const {
    fetchProspectedCampaignLeadProfiles,
    prospectedCampaignLeadProfiles,
    prospectedCampaignLeadProfilesCount,
  } = useProspectedCampaignLeadProfiles();

  const { offset, setOffset } = usePagination({
    pageSize: PAGE_SIZE,
    totalRecords: prospectedCampaignLeadProfilesCount,
  });

  const { campaignLeadProfile, fetchCampaignLeadProfileById } = useCampaignLeadProfileById();

  const {
    mutateAsync: tagCampaignLeadProfileMutation,
    isSuccess: tagCampaignLeadProfileMutationSuccess,
    isLoading: tagCampaignLeadProfileMutationLoading,
  } = useTagCampaignLeadProfileAPI();

  const { mutateAsync: markAsReadMutation, isSuccess: marAsReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: false,
    });

  const { mutateAsync: markAsUnreadMutation, isSuccess: marAsUnReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: true,
    });

  const conversationValueMin = conversationValueMap[conversationValueOption]?.min ?? 0;
  const conversationValueMax =
    conversationValueMap[conversationValueOption]?.max ?? INIFITY_FOR_MAX_CONVERSATION_VALUE;

  const lastReplyDaysAgoFilter =
    lastMessageFilterDays === 0
      ? {}
      : { _gte: dayjs().subtract(lastMessageFilterDays, "day").format("YYYY-MM-DD") };

  const outreachStatusFilter =
    outreachStatusActiveOption === -1
      ? { _is_null: true }
      : outreachStatusActiveOption === 0
      ? {}
      : { _eq: outreachStatusActiveOption };

  const actionDueDateFilter = ACTION_DUE_DATE_MAP[actionDueDate]
    ? {
        _lte: ACTION_DUE_DATE_MAP[actionDueDate].max,
        _gt: ACTION_DUE_DATE_MAP[actionDueDate].min,
      }
    : {};

  useEffect(() => {
    fetchProspectedCampaignLeadProfiles({
      variables: {
        limit: PAGE_SIZE,
        offset: offset,
        orderBy: translateSortingQuery({ sorting }),
        saleActionStatusIds: saleActionStatusIds,
        nameFilter: debouncedSearchTerm ? `%${debouncedSearchTerm}%` : "%%",
        lastReplyFilter: lastReplyDaysAgoFilter,
        outreachStatusFilter,
        workspacesIds: currentWorkspaceId ? [currentWorkspaceId] : [],
        conversationValueMin,
        conversationValueMax,
        actionDueDateFilter,
      },
    });
    if (campaignLeadProfile?.id) {
      fetchCampaignLeadProfileById({ variables: { id: campaignLeadProfile?.id } });
    }
  }, [
    actionDueDate,
    lastMessageFilterDays,
    outreachStatusActiveOption,
    saleActionStatusIds,
    currentWorkspaceId,
    tagCampaignLeadProfileMutationSuccess,
    marAsReadSuccess,
    marAsUnReadSuccess,
    conversationValueMax,
    conversationValueMin,
    offset,
    sorting,
    debouncedSearchTerm,
  ]);

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  const resetFilters = () => {
    setSaleActionStatusIds(DEFAULT_SALE_ACTION_STATUS_IDS);
    setLastMessageFilterDays(0);
    setConversationValueOption(0);
    setSearchTerm("");
    setOutreachStatusActiveOption(0);

    setOffset(0);
    setTriggerFilter(true);
  };

  const isFiltered =
    saleActionStatusIds.length !== saleActionStatuses.length ||
    lastMessageFilterDays !== 0 ||
    conversationValueOption !== 0 ||
    debouncedSearchTerm !== "" ||
    outreachStatusActiveOption !== 0 ||
    actionDueDate !== ACTION_DUE_DATE.ALL;

  const markAsRead = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as read");
      return;
    }
    markAsReadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.READ,
      type: ToggleReadType.WORKSPACE,
    });
  };

  const markAsUnread = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as unread");
      return;
    }
    markAsUnreadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.UNREAD,
      type: ToggleReadType.WORKSPACE,
    });
  };

  const saleActionStatusesOptions = saleActionStatuses.map(status => ({
    id: status.id,
    name: status.name,
  }));

  const closeDrawer = () => {
    searchParams.delete(QUERY_PARAM_LEAD_PROFILE_ID);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Conversations
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            View and manage all your conversations in one place.
          </p>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          searchTerm={searchTerm}
          handleActionDueDateChange={handleActionDueDateChange}
          handleSearchChange={handleSearchNameChange}
          isFiltered={isFiltered}
          onSaleActionStatusChange={onSaleActionStatusChange}
          saleActionStatusesOptions={saleActionStatusesOptions}
          data={prospectedCampaignLeadProfiles}
          columns={columns}
          totalRecords={prospectedCampaignLeadProfilesCount}
          offset={offset}
          resetFilters={resetFilters}
          onOutreachStatusFilterChange={handleOutreachStatusChange}
          setSorting={setSorting}
          triggerFilter={triggerFilter}
          onLastReplyFilterChange={handleLastReplyFilterChange}
          onConversationValueFilterChange={handleConversationValueFilterChange}
          sorting={sorting}
          onPaginationChange={handleOnPaginationChange}
        />
        {campaignLeadProfile && (
          <DataTableDrawer
            tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
            markAsRead={markAsRead}
            markAsUnread={markAsUnread}
            tagCampaignLeadProfileMutationLoading={tagCampaignLeadProfileMutationLoading}
            // outreachAgentTypes={outreachAgentTypes}
            // outreachPlatforms={outreachPlatforms}
            // campaignExternalConnectionProviders={campaignExternalConnectionProviders}
            // updateOutreachAgentMutationLoading={updateOutreachAgentMutationLoading}
            // updateOutreachAgentOnSubmit={updateOutreachAgentOnSubmit}
            // updateOutreachAgentErrors={updateOutreachAgentErrors}
            // updateOutreachAgentControl={updateOutreachAgentControl}
            // updateOutreachAgentRegister={updateOutreachAgentRegister}
            // updateOutreachAgentHandleSubmit={updateOutreachAgentHandleSubmit}
            // updateOutreachAgentSetValue={updateOutreachAgentSetValue}
            open={!!currentCampaignLeadProfileId}
            onOpenChange={closeDrawer}
            data={campaignLeadProfile}
          />
        )}
      </div>
    </>
  );
}
